export const setEvent = ({
	                         sessionId,
	                         language,
	                         location,
	                         type,
	                         redirectFromCountry,
	                         countrySelected,
	                         ageVerified,
	                         step,
	                         content,
	                         handOffPhone,
	                         handOffOnline
                         }) => ({
	type: 'EVENT_SET',
	payload: {
		sessionId,
		language,
		location,
		type,
		redirectFromCountry,
		countrySelected,
		ageVerified,
		step,
		content,
		handOffPhone,
		handOffOnline
	},
});
