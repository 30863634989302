import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store'

export const wrapRootElement = ({ element, location }) => {
	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	// We listen to the resize event
	window.addEventListener('resize', () => {
		// We execute the same script as before
		let vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	})

	return <Provider store={store}>{element}</Provider>
}

// 5c8652ecc37db86fcfcd1e4b
//
// export const onRouteUpdate = ({ location, prevLocation }) => {
//     if (process.env.NODE_ENV === 'production') {
//         ReactGA.pageview(window.location.pathname + window.location.search);
//     }
// };
