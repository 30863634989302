import React, { useState, useRef, useEffect, Fragment, isValidElement } from 'react';
import axios from "axios";
import queryString from "query-string";
import { setEvent } from "@actions/event";

export async function recordEvent(event) {
	if (!!event) {
		try {
			const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://staging.260438f79400.com';
			const url = `${domain}/analytics/tipsAndTricks/record`;
			const { data } = await axios.post(url, event);
			console.log({data});
		} catch (e) {
			console.log(e);
		}
	} else {
		console.log('event data not sent to recordEvent')
	}
}
