import { bakeCookie, readCookie } from '@helpers/storage';

export default (state = readCookie('user'), action) => {
	let newState;

	// Check to see what type of action is being fired
	switch (action.type) {
		case 'USER_ADD':
			newState = action.payload;

			bakeCookie('user', newState);
			return newState;
		default:
			return state ?? null;
	}
};
