import { bakeCookie, readCookie } from '@helpers/storage';
import { recordEvent } from "@helpers/analytics";
import { v4 as uuidv4 } from 'uuid';

export default (state = readCookie('event'), action) => {
	let newState;

	// Check to see what type of action is being fired
	switch (action.type) {
		case 'EVENT_SET':
			newState = action.payload;

			if (!newState.sessionId) newState.sessionId = uuidv4();
			if (!!newState.content) newState.content = {
				fileName: newState.content.fileName,
				url: newState.content.url,
				type: "mp4",
				id: newState.content.fileName
			};
			console.log({ newState })
			bakeCookie('event', newState);
			recordEvent(newState);
			return newState;
		default:
			return state ?? null;
	}
};
